import React from "react"

import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"
import Article from "../components/Article/Article"

const Template = ({ pageContext }) => (
  <Layout>
    <Seo title={pageContext.title} />
    <h1>{pageContext.title}</h1>
    {pageContext.articles.map((article, index) => (
      <Article id={article.slug} key={index}>
        <h2>{article.title}</h2>
        <video playsInline autoPlay muted loop>
          <source src={article.movieUrl} type="video/mp4" />
        </video>
        <p>{article.text}</p>
        <div>
          <img
            alt="ecoinspiracja"
            src="https://eco.kfc.pl/static/img/png/ecoinspiracja.png"
            width="200"
          />
          <h3>{article.subheader}</h3>
          <p>{article.subtext}</p>
        </div>
      </Article>
    ))}
  </Layout>
)

export default Template
