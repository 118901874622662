import React from "react"
import PropTypes from "prop-types"

import "./Article.css"

const Article = ({ children, id }) => <article id={id}>{children}</article>

Article.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Article
